const $ = require('jquery');

$(function () {
    $("#navbar").load("navbar.html");
    let pathname = window.location.pathname;
    let file;
    switch (pathname) {
      case "/":
      case "/home":
        file = "home";
        break;
      case "/menu":
        file = "menu";
        break;
      case "/about":
        file = "about";
        break;
      case "/contact":
        file = "contact";
        break;
      case "/gallery":
        file = "gallery";
        break;
      case "/comments":
        file = "comments";
        break;
      default:
        file = "404";
        break;
    }
    $("#content").load(file + ".html");

    let tmp = "#menu-" + file;
    setTimeout(() => {$("ul > li.active").removeClass("active"); $(tmp).addClass("active"); }, 50);
    console.log("Files loaded");
  });